import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product6 from "./../../../images/es/moonamar-caramelos-con-comino-negro-curcuma-y-menta.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
      description="Caramelos IMMMUNO NATURA con comino negro, cúrcuma, menta y limón – llenos de sabor y buenos para la garganta."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product6}
                alt="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
                title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">
                    Caramelos con comino negro, cúrcuma, menta y limón
                  </div>
                  <div className="subtitle">
                    Contenido: 100 g / 35 caramelos
                  </div>
                  <div className="price">2,50 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">PEDIDOS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    Los caramelos de IMMMUNO NATURA están hechos con una base de
                    aceite de comino negro, curcuma, menta y limón.
                  </p>
                  <p>
                    A parte de su gran sabor tienen un efecto beneficioso para
                    la garganta y las vías respiratorias.
                  </p>
                  <p>
                    Mantener en un lugar seco, fresco y apartado de la luz
                    solar.
                  </p>
                  <p>NO MORDER O TRAGAR.</p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Valor nutritivo</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>por 100 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Valor energético</p>
                        </td>
                        <td>
                          <p>
                            1.653 kJ
                            <br /> 389 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Grasas de las
                            <br />
                            <em>cuales saturadas</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            0,7 g<br />
                            &#60; 0,1 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Hidratos de carbono
                            <br />
                            <em>de los cuales azúcares</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 95,6 g<br /> &lt; 59,0 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Proteínas</p>
                        </td>
                        <td>
                          <p>0,1 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Sal</p>
                        </td>
                        <td>
                          <p>&lt; 0,0 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Ingredientes</h3>
                  <p>
                    Azúcar,jarabe de glucosa, aceite de comino negro, extrato
                    natural de plantas (cúrcuma, zanahoria), aceites etéricos
                    (menta, limón, mentol).{" "}
                  </p>
                  <p>
                    Mantener en un lugar seco, fresco y protegido de la luz
                    solar.
                  </p>
                  <p>NO MORDER O TRAGAR.</p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
